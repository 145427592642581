import React, { useEffect, useState } from "react";
import "antd/dist/antd.css";
import "../assets/styles.scss";
import Main from "../layout/layout";
import { Button, Form, Col, Input, InputNumber, Row } from "antd";
import axios from "axios";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";
import { Script } from "gatsby";
import Reaptcha from "reaptcha";
import SEO from "../layout/seo";

// markup
const Contact = ({location}) => {
  let recaptchaInstance;
  const { t } = useTranslation();
  const [form, setForm] = useState({
    name: "",
    message: "",
    subject: "",
    email: "",
  });

  const [state, setState] = useState("none");
  const validateMessages = {
    required: "${label} " + t("Required"),
    types: {
      email: t("Mail invalid"),
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  const submit = () => {
    setState("pending");
    console.log("form3", form);
    axios
      .post("https://getform.io/f/61afdef6-1239-4a7d-b2ff-072bda65ae13", form)
      .then((response) => {
        clearForm();
        setState("done");
      })
      .catch((error) => setState("error"));
  };

  const clearForm = () => {
    document.getElementById("contactform").reset();
    setForm({
      name: "",
      message: "",
      subject: "",
      email: "",
    });
  };

  const onUpdate = (fields) => {
    let newform = { ...form, [fields[0].name]: fields[0].value };
    setForm(newform);
    console.log(newform);
  };

  const executeCaptcha = function () {
    recaptchaInstance.execute();
  };

  // executed once the captcha has been verified
  // can be used to post forms, redirect, etc.
  const verifyCallback = function (response) {
    console.log(response);
    console.log("form", form);
    submit();
  };

  return (
    <main>
      <SEO title="Contact"
        description={t("Description about")}></SEO>
      <Main location={location}>
        <Script src="https://www.google.com/recaptcha/api.js?render=6LdgEAAjAAAAAPabvMopp1b6ddzIOUABhH4dv13q" />
        <div className="main-container">
          <div className="container-text">
            <div style={{ marginBottom: "2rem" }} className="title-page">
              Contact
              <div className="hr"></div>
            </div>
            <p className="description">hello@victorchevalier.com</p>
            {state === "error" ? (
              <p className="error">
                <Trans>Error contact</Trans>
              </p>
            ) : (
              <></>
            )}
            {state === "done" ? (
              <p className="done">
                <Trans>Success contact</Trans>
              </p>
            ) : (
              <></>
            )}
            <Row>
              <Col xs={24} lg={18} xl={10}>
                <Form
                  id="contactform"
                  onFinish={executeCaptcha}
                  onFieldsChange={onUpdate}
                  name="nest-messages"
                  validateMessages={validateMessages}
                >
                  <Form.Item name={["name"]} rules={[{ required: true }]}>
                    <Input placeholder={t("Name")} />
                  </Form.Item>
                  <Form.Item
                    name={["email"]}
                    rules={[{ required: true, type: "email" }]}
                  >
                    <Input placeholder="EMAIL" />
                  </Form.Item>
                  <Form.Item name={["subject"]} rules={[{ required: true }]}>
                    <Input placeholder={t("Subject")} />
                  </Form.Item>
                  <Form.Item name={["message"]} rules={[{ required: true }]}>
                    <Input.TextArea rows={5} placeholder="MESSAGE" />
                  </Form.Item>
                  <Reaptcha
                    ref={(e) => (recaptchaInstance = e)}
                    sitekey="6LdgEAAjAAAAAPabvMopp1b6ddzIOUABhH4dv13q"
                    size="invisible"
                    onVerify={verifyCallback}
                  />
                  <Form.Item>
                    {state === "pending" ? (
                      <div className="lds-ring">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    ) : (
                      <Button type="primary" htmlType="submit">
                        <Trans>Send</Trans>
                      </Button>
                    )}
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </div>
        </div>
      </Main>
    </main>
  );
};
export const queryContact = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
export default Contact;
